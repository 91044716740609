<template>
  <div>
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12 md12>
        <v-img
          v-if="product.coverImage"
          :src="mediaURL + product.coverImage"
          width="100%"
          contain
        >
          <v-layout wrap justify-center fill-height hidden-xs-only>
            <v-flex xs4 align-self-end>
              <v-card tile color="#000000" elevation="5" dark width="350px">
                <v-layout wrap justify-center>
                  <v-flex xs12 pa-6 text-left>
                    <span style=" 	font- family: poppinsbold; font-size: 28px">
                      {{ product.name }}
                    </span>
                  </v-flex>
                  <v-flex xs12>
                    <v-progress-linear
                      value="100"
                      color="#FFE200"
                    ></v-progress-linear>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
            <v-flex xs12 sm6 md3 align-self-end text-right>
              <router-link :to="'/editProduct/' + product._id">
                <v-btn
                  color="#005f32"
                  small
                  tile
                  class="px-3"
                  :ripple="false"
                  depressed
                >
                  <span
                    style="
                      font-size: 12px;
                      color: #ffffff;
                      font-family: poppinsregular;
                    "
                  >
                    Edit Product
                  </span>
                </v-btn>
              </router-link>
            </v-flex>
          </v-layout>
        </v-img>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center hidden-sm-and-up>
      <v-flex xs6 sm6 md6 align-self-end text-right pa-4>
        <router-link :to="'/editProduct/' + product._id">
          <v-btn
            color="#005f32"
            small
            block
            tile
            class="px-3"
            :ripple="false"
            depressed
          >
            <span
              style="
                font-size: 12px;
                color: #ffffff;
                font-family: poppinsregular;
              "
            >
              Edit Product
            </span>
          </v-btn>
        </router-link>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center py-4 px-2>
      <v-flex xs12 lg11 xl11>
        <v-layout wrap justify-center>
          <v-flex xs12 sm12 md8 lg8 xl8>
            <v-layout wrap justify-start>
              <v-flex sm10>
                <v-layout wrap>
                  <v-flex xs12  pt-3 text-left>
                    <v-checkbox
                      v-model="product.makebanner"
                      readonly
                      label="Product will appear as banner in website?"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 sm6 pt-3 text-left>
                    <span style="font-size: 16px; font-family: poppinssemibold">
                      Banner Text
                    </span>
                    <!-- <span
                  style="color: #9b9090; text-align: left"
                  v-html="product.price"
                >
                </span> -->
                  </v-flex>
                  
                  <v-flex xs12 sm6 pt-3 text-left>
                    <span style="color: #9b9090; text-align: left"
                      v-html="product.bannertext"> </span
                    >
                  </v-flex>
                  <v-flex xs12 sm6 pt-3 text-left>
                    <span style="font-size: 16px; font-family: poppinssemibold">
                      Price
                    </span>
                    <!-- <span
                  style="color: #9b9090; text-align: left"
                  v-html="product.price"
                >
                </span> -->
                  </v-flex>
                  
                  <v-flex xs12 sm6 pt-3 text-left>
                    <span style="color: #9b9090; text-align: left"
                      ><b> {{ product.price }} INR </b></span
                    >
                  </v-flex>
                </v-layout>
                <v-layout wrap>
                  <v-flex xs12 sm6 pt-3 text-left>
                    <span style="font-size: 16px; font-family: poppinssemibold">
                      Description
                    </span>
                  </v-flex>
                  <v-flex xs12 sm6 pt-3 text-left>
                    <span
                      style="color: #9b9090; text-align: left"
                      v-html="product.description"
                    ></span>
                  </v-flex>
                </v-layout>
                <v-layout wrap>
                  <v-flex xs12 sm6 pt-3 text-left>
                    <span
                      style="color: #9b9090; text-align: left"
                      v-html="product.unit"
                    ></span>
                  </v-flex>
                </v-layout>
                <v-layout wrap>
                  <v-flex xs12 sm6 pt-3 text-left>
                    <span style="font-size: 16px; font-family: poppinssemibold">
                      Stock
                    </span>
                  </v-flex>
                  <v-flex xs12 sm6 pt-3 text-left>
                    <span
                      v-if="product.totalQuantity == 0"
                      style="color: red; text-align: left"
                    >
                      <b> Out of Stock </b></span
                    >
                    <span v-else style="color: #9b9090; text-align: left">
                      <b> {{ product.totalQuantity }} </b></span
                    >
                  </v-flex>
                </v-layout>
                <v-layout wrap v-if="product.speciality">
                  <v-flex xs12 sm6 pt-3 text-left v-if="product.speciality">
                    <span style="font-size: 16px; font-family: poppinssemibold">
                      Speciality
                    </span>
                  </v-flex>
                  <v-flex xs12 sm6 pt-3 text-left>
                    <span
                      style="color: #9b9090; text-align: left"
                      v-html="product.speciality"
                    ></span>
                  </v-flex>
                </v-layout>
                <v-layout wrap>
                  <v-flex xs12 sm6 pt-3 v-if="product.category" text-left>
                    <span style="font-size: 16px; font-family: poppinssemibold">
                      Category
                    </span>
                  </v-flex>
                  <!-- <v-flex xs12 sm6 pt-3 text-left>
                    <span>
                      <span
                    style="color: #9b9090; text-align: left"
                    v-html="product.category"
                  >
                  </span>
                      <v-select
                        color="#005f32"
                        :items="categories"
                        dense
                        v-model="product.category"
                        item-value="_id"
                        item-text="name"
                        readonly
                        outlined
                      ></v-select>
                    </span>
                  </v-flex> -->
                </v-layout>
              </v-flex>
              <v-flex xs12 md12 lg12 xl12 v-if="product.length > 0">
                <v-layout wrap justify-center pt-3>
                  <v-flex xs12 text-left>
                    <span class="styloopinputlabel" style="font-size: 16px"
                      >Product Units</span
                    >
                  </v-flex>
                  <v-flex xs12 pt-3>
                    <StockViewer
                      v-bind:storage="product"
                      v-bind:sizeData="product.units"
                      @stepper="winStepper"
                    />
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm6 md4 lg4 xl4 text-center>
            <v-layout wrap justify-center>
              <!-- <v-flex xs12 v-if="product.onlinePercent < 1">
                <span class="tableHeading">
                  This productme is not available for online Booking
                </span>
              </v-flex> -->
              <v-flex xs12 py-4 v-if="product.images">
                <v-layout wrap justify-center v-if="product.images.length > 0">
                  <viewer :images="product.images" :key="product.images.length">
                    <span
                      v-for="item in product.images"
                      :key="item"
                      class="pa-2"
                    >
                      <img
                        :src="mediaURL + item"
                        height="150px"
                        width="150px"
                      />
                    </span>
                  </viewer>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <div v-if="id">
      <Reviews :productid="id" />
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Reviews from "./reviews";
import StockViewer from "./stockViewer";
export default {
  props: ["id"],
  components: {
    Reviews,
    StockViewer,
  },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      product: {},
      pricing: [],
      packageFacility: [],
      isAddMore: true,
      slots: [],
      packageView: false,
      slotView: false,
      badgeColor: "error",
      btnColor: "success",
      selectedCombo: [],
      productsdata: [],
      categories: [],
      selcategory: "",
      // productsCombo: [],
    };
  },
  beforeMount() {
    this.getData();
    this.getCategories();
  },
  methods: {
    winStepper(window_data) {
      if (window_data.productStock) {
        this.productStock = window_data.productStock;
        this.product.units = this.productStock;
        // if(this.winStepper.type=="edit")
        // this.getData();
      } else if (window_data.type == "imageDeletion") {
        this.getData();
      }
    },
    getCategories() {
      axios({
        method: "GET",
        url: "/productcategory/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.categories = response.data.data;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/product/get",
        data: {
          id: this.id,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          // if (response.data.package) this.pricing = response.data.package;
          if (response.data.status) {
            this.product = response.data.data;
            this.getproducts();
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getproducts() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/product/getlist",
      })
        .then((response) => {
          // for (var i = 0; i < this.categories.length; i++) {
          //     if(this.categories[i]._id.includes(this.product.category))
          //     this.selcategory=this.categories[i].name
          //     alert("xsd",this.product.category)
          //   }
          this.appLoading = false;
          this.productsdata = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatTime(item) {
      if (!item) return;
      var hours = Number(item.split(":")[0]);
      var minutes = Number(item.split(":")[1]);
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },
    // info(id) {
    //   this.$router.push("/packageRates/" + id);
    // },
  },
};
</script>
